import React, { lazy, Suspense } from 'react';
import CollpasedProvider from '@/providers/CollpasedProvider';
import PusherProvider from '@/providers/PusherProvider.native';
import { ConversationProvider } from '@/providers/ConversationProvider';
import {
  CardStyleInterpolators,
  createStackNavigator,
  StackNavigationOptions
} from '@react-navigation/stack';
import { Dimensions } from 'react-native';
import HeaderInfo from '@/components/header/HeaderInfo';
import colors from '../../../config/colors';
import { RootState, useAppSelector } from '@/redux/store';
import { Routes } from '@/routers/routes';

const NewConversationExternal = lazy(() => import('@/screens/NewConversationExternal'));
const ConversationStack = lazy(() => import('@/routers/ConversationStack'));
const MobileStackNavigator = lazy(() => import('@/routers/MobileStackNavigation'));
const AutomatizationTemplate = lazy(() => import('@/screens/AutomatizationTemplate'));
const AutomatizationRange = lazy(() => import('@/screens/AutomatizationRange'));

import HeaderAppBar from '@/atoms/HeaderAppBar';
import i18n from '@/providers/Localization';
import translate from '@/helpers/traslations';

const Stack = createStackNavigator();

const AppStackMobile = () => {
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const teamId = useAppSelector((state: RootState) => state.user.team?.id);

  const screenOptions: StackNavigationOptions = {
    gestureEnabled: false,
    gestureResponseDistance: Dimensions.get('screen').width,
    header: () => <HeaderInfo />,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
  };

  return (
    <Suspense fallback={null}>
      <PusherProvider>
        <CollpasedProvider>
          <ConversationProvider>
            <Stack.Navigator
              screenOptions={{
                ...screenOptions,
                cardStyle: {
                  backgroundColor: colors[themeMode]?.background
                }
              }}
            >
              <Stack.Screen
                options={{
                  animationEnabled: false,
                  cardStyle: {
                    flex: 1,
                    height: '100%',
                    backgroundColor: colors[themeMode]?.background
                  }
                }}
                name={Routes.Team}
                initialParams={{ team: teamId, id: '' }}
                component={MobileStackNavigator}
              />
              <Stack.Screen
                options={{
                  animationEnabled: true,
                  gestureEnabled: true,
                  gestureDirection: 'horizontal',
                  cardStyle: {
                    flex: 1,
                    height: '100%',
                    backgroundColor: colors[themeMode]?.background
                  }
                }}
                name={Routes.ConversationStack}
                component={ConversationStack}
              />
              <Stack.Screen
                name={Routes.AutomatizationTemplate}
                initialParams={{ team: teamId }}
                component={AutomatizationTemplate}
                options={{
                  animationEnabled: false,
                  headerShown: true,
                  header: () => (
                    <HeaderAppBar idStringTitle="navigation.newAutomatizationTemplate" />
                  ),
                  cardStyle: {
                    flex: 1,
                    backgroundColor: colors[themeMode]?.background,
                    elevation: 5
                  },
                  title: i18n.t('navigation.newAutomatizationTemplate')
                }}
              />
              <Stack.Screen
                name={Routes.AutomatizationBot}
                component={AutomatizationTemplate}
                initialParams={{
                  team: teamId
                }}
                options={{
                  animationEnabled: true,
                  headerShown: true,
                  header: () => <HeaderAppBar idStringTitle="navigation.newAutomatizationBot" />,
                  title: translate('navigation.newAutomatizationBot'),
                  cardStyle: {
                    backgroundColor: colors[themeMode]?.backgroundSecondary,
                    borderLeftWidth: 1,
                    borderLeftColor: colors[themeMode]?.border
                  }
                }}
              />
              <Stack.Screen
                name={Routes.AutomatizationRange}
                component={AutomatizationRange}
                options={{
                  headerShown: true,
                  header: () => (
                    <HeaderAppBar
                      ctaBack={null}
                      idStringTitle="navigation.newAutomatizationRange"
                    />
                  ),
                  title: translate('navigation.newAutomatizationRange'),
                  cardStyle: {
                    backgroundColor: colors[themeMode]?.backgroundSecondary,
                    borderLeftWidth: 1,
                    borderLeftColor: colors[themeMode]?.border,
                    flex: 1
                  }
                }}
              />
              <Stack.Screen
                name={Routes.NewConversationExternal}
                component={NewConversationExternal}
                options={{
                  animationEnabled: true,
                  header: () => <HeaderAppBar idStringTitle="newConversation" />,
                  headerShown: true,
                  cardStyle: {
                    flex: 1,
                    backgroundColor: colors[themeMode]?.background,
                    elevation: 5
                  },
                  title: translate('newConversation')
                }}
              />
            </Stack.Navigator>
          </ConversationProvider>
        </CollpasedProvider>
      </PusherProvider>
    </Suspense>
  );
};

export default AppStackMobile;
