import React, { memo } from 'react';

import SoundNotifications from '@/components/partials/notifications/SoundNotifications';
import ChannelsNotifications from '@/components/partials/notifications/ChannelsNotifications';
import BrowserNotifications from '@/components/partials/notifications/BrowserNotifications';
import { Platform } from 'react-native';
import { ThemedScrollView } from '@/components/themed';

const { useNotifications } = Platform.select({
  native: () => require('@/providers/NotificationProvider.native'),
  default: () => require('@/providers/NotificationProvider.web')
})();

function SettingsNotifications() {
  const { notificationsEnabled } = useNotifications();

  return (
    <ThemedScrollView
      showsVerticalScrollIndicator={false}
      bgColor="backgroundSecondary"
      style={{
        padding: 18,
        height: '100%'
      }}
    >
      <BrowserNotifications />
      {notificationsEnabled && (
        <>
          <SoundNotifications />
          <ChannelsNotifications />
        </>
      )}
    </ThemedScrollView>
  );
}

export default memo(SettingsNotifications);
